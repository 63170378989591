<template>
  <v-container fluid>
    <!-- CONTENT START -->
    <v-row>
      <!-- ŰRLAP START -->
      <v-col cols="9">
        <v-row v-if="form.settings.showTitle === 1"
               :justify="form.settings.titleAlign"
               :class="`text-${form.settings.titleSize}`"
               class="mb-6 mx-0">
          {{ form.settings.title }}
        </v-row>
        <draggable v-model="form.blocks" class="row mx-0" animation="400">
          <template v-for="(block, bindex) in form.blocks">
            <v-col :cols="12/block.settings.xsWidth"
                   :sm="12/block.settings.smWidth"
                   :md="12/block.settings.mdWidth"
                   :lg="12/block.settings.lgWidth"
                   :key="bindex"
                   class="fe-drag-input-wrapper">
              <v-row>
                <v-col cols="12">
                  <v-row class="mx-0 mb-4">
                    <v-icon size="24" style="cursor: move;">
                      mdi-cursor-move
                    </v-icon>
                    <v-spacer></v-spacer>
                    <v-icon size="24" class="mr-4" @click="openFieldDialog(bindex)">
                      mdi-text-box-plus-outline
                    </v-icon>
                    <v-icon size="24" class="mr-4" @click="editBlockInDialog(block.settings, bindex)">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="24" @click="deleteBlock(bindex)">
                      mdi-delete
                    </v-icon>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row v-if="block.settings.showTitle === 1"
                         :justify="block.settings.titleAlign"
                         :class="`text-${block.settings.titleSize}`"
                         class="mx-0">
                    {{ block.settings.title }}
                  </v-row>
                </v-col>
                <v-col col="12">
                  <v-card :flat="block.settings.displayStyle === 2">
                    <v-card-title v-if="block.settings.displayStyle === 1">
                      {{ block.settings.title }}
                    </v-card-title>
                    <v-divider v-if="block.settings.displayStyle === 1"></v-divider>
                    <v-card-text class="pa-4">
                      <draggable v-model="block.fields" class="row mx-0" animation="400">
                        <template v-for="(item, findex) in block.fields">
                          <v-col :cols="12/item.xsWidth"
                                 :sm="12/item.smWidth"
                                 :md="12/item.mdWidth"
                                 :lg="12/item.lgWidth"
                                 :key="findex"
                                 class="fe-drag-input-wrapper">
                            <v-row class="mx-0 mb-4">
                              <v-icon size="24" style="cursor: move;">
                                mdi-cursor-move
                              </v-icon>
                              <v-spacer></v-spacer>
                              <v-icon size="24" class="mr-4" @click="editFieldInDialog(item, bindex, findex)">
                                mdi-pencil
                              </v-icon>
                              <v-icon size="24" @click="deleteField(bindex, findex)">
                                mdi-delete
                              </v-icon>
                            </v-row>
                            <template v-if="item.type === 1">
                              <v-text-field :label="item.label"
                                            v-model="item.value"
                                            :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                            persistent-hint
                                            filled
                                            dense
                                            class="fe-drag-input">
                              </v-text-field>
                            </template>
                            <template v-if="item.type === 2">
                              <v-select :label="item.label"
                                        v-model="item.value"
                                        :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                        persistent-hint
                                        filled
                                        dense
                                        class="fe-drag-input">
                              </v-select>
                            </template>
                            <template v-if="item.type === 3">
                              <v-textarea :label="item.label"
                                          v-model="item.value"
                                          :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                          persistent-hint
                                          filled
                                          dense
                                          class="fe-drag-input">
                              </v-textarea>
                            </template>
                            <template v-if="item.type === 4">
                              <v-text-field v-model="item.value"
                                            :label="item.label"
                                            :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            @click="openDatePicker(item, bindex, findex, 1)"
                                            @click:append="openDatePicker(item, bindex, findex, 1)"
                                            filled
                                            dense
                                            readonly
                                            class="fe-drag-input">
                              </v-text-field>
                            </template>
                            <template v-if="item.type === 5">
                              <v-text-field v-model="item.value"
                                            :label="item.label"
                                            :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            @click="openDatePicker(item, bindex, findex, 2)"
                                            @click:append="openDatePicker(item, bindex, findex, 2)"
                                            filled
                                            dense
                                            readonly
                                            class="fe-drag-input">
                              </v-text-field>
                            </template>
                            <template v-if="item.type === 6">
                              <v-text-field :label="item.label"
                                            v-model="item.value"
                                            :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                            persistent-hint
                                            v-mask="dateTextMask"
                                            filled
                                            dense
                                            class="fe-drag-input">
                              </v-text-field>
                            </template>
                            <template v-if="item.type === 7">
                              <div v-if="item.showLabel === 1" class="body-2 pb-2">
                                {{ item.label }}
                              </div>
                              <template v-for="(chb, cindex) in item.children">
                                <v-row class="mx-0" :key="cindex">
                                  <v-col cols="12" class="pa-0">
                                    <v-row>
                                      <v-checkbox v-model="chb.value"
                                                  hide-details
                                                  :error="chb.isRequired === 2 ? chb.value == null ? false : !chb.value : false"
                                                  class="fe-drag-input mt-0 pt-0">
                                      </v-checkbox>
                                      <div class="fe-drag-input"
                                           :class="chb.isRequired === 2 ? chb.value == null ? '' : chb.value ? '' : 'red--text' : ''"
                                           v-html="chb.label">
                                      </div>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" class="pa-0">
                                    <div class="caption" style="letter-spacing: normal !important;">
                                        <span :class="chb.isRequired === 2 ? chb.value == null ? '' : chb.value? '' : 'red--text' : ''">
                                          {{ chb.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni' }}
                                        </span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                            <template v-if="item.type === 8">
                              <v-radio-group v-model="item.value" hide-details>
                                <template v-slot:label>
                                  <div v-if="item.showLabel === 1">
                                    {{ item.label }}
                                  </div>
                                </template>
                                <template v-for="(radio, index) in item.children">
                                  <v-row :key="index">
                                    <v-radio :value="radio.value" class="mb-0"></v-radio>
                                    <div class="fe-drag-input body-2"
                                         v-html="radio.label">
                                    </div>
                                  </v-row>
                                </template>
                              </v-radio-group>
                              <div class="caption" style="letter-spacing: normal !important;">
                                <span :class="item.isRequired === 2 ? item.value == null ? '' : item.value? '' : 'red--text' : ''">
                                  {{ item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni' }}
                                </span>
                              </div>
                            </template>
                          </v-col>
                        </template>
                      </draggable>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </draggable>
        <v-row :justify="form.settings.submit.align" class="mx-0">
          <v-btn color="primary"
                 :text="form.settings.submit.display === 3"
                 :outlined="form.settings.submit.display === 2"
                 :block="form.settings.submit.isBlock === 2"
                 :rounded="form.settings.submit.rounded === 2"
                 :small="form.settings.submit.size === 2"
                 :x-small="form.settings.submit.size === 3"
                 :large="form.settings.submit.size === 4"
                 :x-large="form.settings.submit.size === 5">
            {{ form.settings.submit.text }}
          </v-btn>
        </v-row>
      </v-col>
      <!-- ŰRLAP END -->
      <!-- ŰRLAP BEÁLLÍTÁSOK START -->
      <v-col cols="3">
        <v-card>
          <v-card-title>
            <span>{{ $t('formSettings') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-4">
            <v-row class="mx-0 mb-5">
              <v-btn color="primary"
                     block
                     @click="openBlockDialog">
                {{ $t('addNewBlock') }}
              </v-btn>
            </v-row>
            <v-form v-model="formSettings">
              <v-divider class="mb-6"></v-divider>
              <v-text-field v-model="form.settings.title"
                            :label="$t('labels.formTitle')"
                            :rules="[$store.state.inputRules.requiredField]"
                            dense
                            filled>
              </v-text-field>
              <v-select v-model="form.settings.showTitle"
                        :label="$t('labels.showFormTitle')"
                        :items="selectFieldOptions.yesOrNo"
                        dense
                        filled>
              </v-select>
              <v-select v-model="form.settings.titleSize"
                        :label="$t('labels.titleSize')"
                        :items="selectFieldOptions.titleSizes"
                        dense
                        filled>
              </v-select>
              <v-select v-model="form.settings.titleAlign"
                        :label="$t('labels.titleAlign')"
                        :items="selectFieldOptions.titleAlign"
                        dense
                        filled>
              </v-select>
              <v-divider class="mb-6"></v-divider>
              <v-text-field v-model="form.settings.submit.text"
                            :label="$t('labels.submitText')"
                            :rules="[$store.state.inputRules.requiredField]"
                            dense
                            filled>
              </v-text-field>
              <v-select v-model="form.settings.submit.align"
                        :label="$t('labels.submitAlign')"
                        :items="selectFieldOptions.titleAlign"
                        dense
                        filled>
              </v-select>
              <v-select v-model="form.settings.submit.display"
                        :label="$t('labels.submitDisplay')"
                        :items="selectFieldOptions.submitDisplay"
                        dense
                        filled>
              </v-select>
              <v-select v-model="form.settings.submit.isBlock"
                        :label="$t('labels.submitIsBlock')"
                        :items="selectFieldOptions.submitIsBlock"
                        dense
                        filled>
              </v-select>
              <v-select v-model="form.settings.submit.rounded"
                        :label="$t('labels.submitRounded')"
                        :items="selectFieldOptions.submitRounded"
                        dense
                        filled>
              </v-select>
              <v-select v-model="form.settings.submit.size"
                        :label="$t('labels.submitSize')"
                        :items="selectFieldOptions.submitSize"
                        dense
                        filled>
              </v-select>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="primary"
                   class="mr-3"
                   @click="openFormPreview">
              {{ $t('button.openPreview') }}
            </v-btn>
            <v-btn color="primary"
                   @click="saveForm">
              {{ $t('button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- ŰRLAP BEÁLLÍTÁSOK END -->
    </v-row>
    <!-- CONTENT END -->
    <!-- INPUT HOZZÁADÁSA / SZERKESZTÉSE START -->
    <v-dialog v-model="newFieldDialog.isVisible" persistent scrollable max-width="1024">
      <v-card>
        <v-card-title class="indigo white--text">
          <span>{{ newFieldDialog.action === 'ADD' ? $t('addNewField') : $t('editField')}}</span>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="closeFieldDialog">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form v-model="newFieldForm" ref="newFieldForm">
            <v-row>
              <v-col cols="6">
                <v-select v-model="selectedElement.type"
                          :label="$t('labels.type')"
                          :items="availableElements"
                          item-value="id"
                          item-text="text"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedElement.label"
                              :label="$t('labels.label')"
                              :rules="[$store.state.inputRules.requiredField]"
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="6" v-if="selectedElement.type !== 7">
                <v-select v-model="selectedElement.isRequired"
                          :label="$t('labels.isRequired')"
                          :items="selectFieldOptions.isRequiredOptions"
                          item-value="id"
                          item-text="text"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </v-col>
              <template v-if="selectedElement.type === 7 || selectedElement.type === 8">
                <v-col cols="6">
                  <v-select v-model="selectedElement.showLabel"
                            :label="$t('labels.showLabel')"
                            :items="selectFieldOptions.yesOrNo"
                            :rules="[$store.state.inputRules.requiredField]"
                            dense
                            filled>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <table class="custom-table">
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t('text') }}
                        </th>
                        <th class="text-left">
                          {{ selectedElement.type === 7 ? $t('mandatory') : $t('value') }}
                        </th>
                        <th class="text-right">
                          <v-btn color="primary"
                                 small @click="openTexteditorDialog">
                            {{ $t('button.addNew') }}
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(radio, index) in selectedElement.children">
                        <tr :key="index">
                          <td v-html="radio.label"></td>
                          <td>
                            {{ selectedElement.type === 7 ? radio.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni' : radio.value }}
                          </td>
                          <td class="text-right">
                            <v-btn icon @click="editTexteditorContent(radio, index)">
                              <v-icon>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                            <v-btn icon @click="deleteRadioOrChb(index)">
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                      <template v-if="selectedElement.children.length < 1">
                        <tr>
                          <td colspan="3" class="text-center text-subtitle-1 red--text font-weight-bold py-2">
                            {{ $t('hints.atLeastOneOptionIsMandatory') }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-col>
              </template>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select v-model="selectedElement.xsWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-cellphone"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="selectedElement.smWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-tablet"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="selectedElement.mdWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-laptop"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="selectedElement.lgWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-monitor"
                          dense
                          filled>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-5 pb-5">
          <v-spacer></v-spacer>
          <v-btn text
                 class="mr-4"
                 @click="closeFieldDialog">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn v-if="newFieldDialog.action === 'ADD'"
                 color="primary"
                 @click="addFieldToBlock"
                 :disabled="newFieldDialogActionButtonIsDisabled">
            {{ $t('button.add') }}
          </v-btn>
          <v-btn v-if="newFieldDialog.action === 'EDIT'"
                 color="primary"
                 @click="updateField"
                 :disabled="newFieldDialogActionButtonIsDisabled">
            {{ $t('button.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- INPUT HOZZÁADÁSA / SZERKESZTÉSE END -->
    <!-- BLOKK HOZZÁADÁSA / SZERKESZTÉSE START -->
    <v-dialog v-model="newBlockDialog.isVisible" persistent scrollable max-width="1024">
      <v-card>
        <v-card-title class="indigo white--text">
          <span>{{ newBlockDialog.action === 'ADD' ? $t('addNewBlock') : $t('editBlock')}}</span>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="closeBlockDialog">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form v-model="newBlockForm" ref="newBlockForm">
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="selectedBlock.settings.title"
                              :label="$t('labels.blockTitle')"
                              :rules="[$store.state.inputRules.requiredField]"
                              dense
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="selectedBlock.settings.displayStyle"
                          :label="$t('labels.blockVisualization')"
                          :items="selectFieldOptions.formBackground"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-model="selectedBlock.settings.showTitle"
                          :label="$t('labels.showFormTitle')"
                          :items="selectFieldOptions.yesOrNo"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-model="selectedBlock.settings.titleSize"
                          :label="$t('labels.titleSize')"
                          :items="selectFieldOptions.titleSizes"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-model="selectedBlock.settings.titleAlign"
                          :label="$t('labels.titleAlign')"
                          :items="selectFieldOptions.titleAlign"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select v-model="selectedBlock.settings.xsWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-cellphone"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="selectedBlock.settings.smWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-tablet"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="selectedBlock.settings.mdWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-laptop"
                          dense
                          filled>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="selectedBlock.settings.lgWidth"
                          :label="$t('labels.width')"
                          :items="selectFieldOptions.widths"
                          :rules="[$store.state.inputRules.requiredField]"
                          prepend-inner-icon="mdi-monitor"
                          dense
                          filled>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-5 pb-5">
          <v-spacer></v-spacer>
          <v-btn text
                 class="mr-4"
                 @click="closeBlockDialog">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn v-if="newBlockDialog.action === 'ADD'"
                 color="primary"
                 @click="addBlockToForm"
                 :disabled="!newBlockForm">
            {{ $t('button.add') }}
          </v-btn>
          <v-btn v-if="newBlockDialog.action === 'EDIT'"
                 color="primary"
                 @click="updateBlock"
                 :disabled="!newBlockForm">
            {{ $t('button.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- BLOKK HOZZÁADÁSA / SZERKESZTÉSE END -->
    <!-- ŰRLAP ELŐNÉZET START -->
    <v-dialog v-model="previewDialog" scrollable max-width="1300">
      <v-card>
        <v-card-title class="indigo white--text">
          <span>{{ $t('button.openPreview') }}</span>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="closeFormPreview">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-row v-if="form.settings.showTitle === 1"
                 :justify="form.settings.titleAlign"
                 :class="`text-${form.settings.titleSize} mx-0`"
                 class="mb-6 mx-0">
            {{ form.settings.title }}
          </v-row>
          <v-row class="mx-0">
            <template v-for="(block, bindex) in form.blocks">
              <v-col :cols="12/block.settings.xsWidth"
                     :sm="12/block.settings.smWidth"
                     :md="12/block.settings.mdWidth"
                     :lg="12/block.settings.lgWidth"
                     :key="bindex">
                <v-row>
                  <v-col cols="12"
                         class="pa-0"
                         v-if="block.settings.showTitle === 1">
                    <v-row :justify="block.settings.titleAlign"
                           :class="`text-${block.settings.titleSize}`"
                           class="mx-0 mb-5">
                      {{ block.settings.title }}
                    </v-row>
                  </v-col>
                  <v-col col="12" class="pa-0">
                    <v-card :flat="block.settings.displayStyle === 2">
                      <v-card-title v-if="block.settings.displayStyle === 1">
                        {{ block.settings.title }}
                      </v-card-title>
                      <v-divider v-if="block.settings.displayStyle === 1"></v-divider>
                      <v-card-text :class="block.settings.displayStyle === 2 ? 'pa-0' : 'pa-4'">
                        <v-row class="mx-0">
                          <template v-for="(item, findex) in block.fields">
                            <v-col :cols="12/item.xsWidth"
                                   :sm="12/item.smWidth"
                                   :md="12/item.mdWidth"
                                   :lg="12/item.lgWidth"
                                   :key="findex">
                              <template v-if="item.type === 1">
                                <v-text-field :label="item.label"
                                              :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                              persistent-hint
                                              filled
                                              dense
                                              class="fe-drag-input">
                                </v-text-field>
                              </template>
                              <template v-if="item.type === 2">
                                <v-select :label="item.label"
                                          :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                          persistent-hint
                                          filled
                                          dense
                                          class="fe-drag-input">
                                </v-select>
                              </template>
                              <template v-if="item.type === 3">
                                <v-textarea :label="item.label"
                                            :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                            persistent-hint
                                            filled
                                            dense
                                            class="fe-drag-input">
                                </v-textarea>
                              </template>
                              <template v-if="item.type === 4">
                                <v-text-field v-model="item.value"
                                              :label="item.label"
                                              :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                              persistent-hint
                                              append-icon="mdi-calendar"
                                              @click="openDatePicker(item, bindex, findex, 1)"
                                              @click:append="openDatePicker(item, bindex, findex, 1)"
                                              filled
                                              dense
                                              readonly
                                              class="fe-drag-input">
                                </v-text-field>
                              </template>
                              <template v-if="item.type === 5">
                                <v-text-field v-model="item.value"
                                              :label="item.label"
                                              :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                              persistent-hint
                                              append-icon="mdi-calendar"
                                              @click="openDatePicker(item, bindex, findex, 2)"
                                              @click:append="openDatePicker(item, bindex, findex, 2)"
                                              filled
                                              dense
                                              readonly
                                              class="fe-drag-input">
                                </v-text-field>
                              </template>
                              <template v-if="item.type === 6">
                                <v-text-field :label="item.label"
                                              v-model="item.value"
                                              :hint="item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni'"
                                              persistent-hint
                                              v-mask="dateTextMask"
                                              filled
                                              dense
                                              class="fe-drag-input">
                                </v-text-field>
                              </template>
                              <template v-if="item.type === 7">
                                <div v-if="item.showLabel === 1" class="body-2 pb-2">
                                  {{ item.label }}
                                </div>
                                <template v-for="(chb, cindex) in item.children">
                                  <v-row class="mx-0" :key="cindex">
                                    <v-col cols="12" class="pa-0">
                                      <v-row>
                                        <v-checkbox v-model="chb.value"
                                                    hide-details
                                                    :error="chb.isRequired === 2 ? chb.value == null ? false : !chb.value : false"
                                                    class="fe-drag-input mt-0 pt-0">
                                        </v-checkbox>
                                        <div class="fe-drag-input"
                                             :class="chb.isRequired === 2 ? chb.value == null ? '' : chb.value ? '' : 'red--text' : ''"
                                             v-html="chb.label">
                                        </div>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" class="pa-0">
                                      <div class="caption" style="letter-spacing: normal !important;">
                                        <span :class="chb.isRequired === 2 ? chb.value == null ? '' : chb.value? '' : 'red--text' : ''">
                                          {{ chb.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni' }}
                                        </span>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </template>
                              </template>
                              <template v-if="item.type === 8">
                                <v-radio-group v-model="item.value" hide-details>
                                  <template v-slot:label>
                                    <div v-if="item.showLabel === 1">
                                      {{ item.label }}
                                    </div>
                                  </template>
                                  <template v-for="(radio, index) in item.children">
                                    <v-row :key="index">
                                      <v-radio :value="radio.value" class="mb-0"></v-radio>
                                      <div class="fe-drag-input body-2"
                                           v-html="radio.label">
                                      </div>
                                    </v-row>
                                  </template>
                                </v-radio-group>
                                <div class="caption" style="letter-spacing: normal !important;">
                                <span :class="item.isRequired === 2 ? item.value == null ? '' : item.value? '' : 'red--text' : ''">
                                  {{ item.isRequired === 1 ? 'Opcionális' : 'Kötelező kitölteni' }}
                                </span>
                                </div>
                              </template>
                            </v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
          <v-row :justify="form.settings.submit.align" class="mx-0 px-3">
            <v-btn color="primary"
                   :text="form.settings.submit.display === 3"
                   :outlined="form.settings.submit.display === 2"
                   :block="form.settings.submit.isBlock === 2"
                   :rounded="form.settings.submit.rounded === 2"
                   :small="form.settings.submit.size === 2"
                   :x-small="form.settings.submit.size === 3"
                   :large="form.settings.submit.size === 4"
                   :x-large="form.settings.submit.size === 5">
              {{ form.settings.submit.text }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ŰRLAP ELŐNÉZET END -->
    <!-- NAPTÁR START -->
    <v-dialog v-model="datePickerDialog.isVisible"
              persistent
              width="290px">
      <v-date-picker v-model="selectedElement.value"
                     ref="datePicker"
                     :first-day-of-week="1"
                     :locale="$i18n.locale"
                     @input="saveDateFromDatePicker">
        <v-spacer></v-spacer>
        <v-btn text
               color="primary"
               @click="closeDatePicker">
          {{ $t('button.cancel') }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <!-- NAPTÁR END -->
    <!-- SZÖVEGSZERKESZTŐ START -->
    <v-dialog v-model="texteditorDialog.isVisible" persistent max-width="768">
      <v-card>
        <v-card-title class="indigo white--text">
          {{ selectedElement.type === 7 ? $t('editChbText') : $t('editRadioText') }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="cancelNewTexteditor">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="8" class="py-0">
              <v-row>
                <!-- SZÖVEGSZERKESZTŐ START -->
                <v-col cols="12" class="pa-0">
                  r
                </v-col>
                <!-- SZÖVEGSZERKESZTŐ END -->
              </v-row>
            </v-col>
            <v-col cols="4" class="py-0">
              <div v-if="selectedElement.type === 7">
                <v-select v-model="texteditorDialog.content.isRequired"
                          :label="$t('labels.isRequired')"
                          :items="selectFieldOptions.isRequiredOptions"
                          item-value="id"
                          item-text="text"
                          :rules="[$store.state.inputRules.requiredField]"
                          dense
                          filled>
                </v-select>
              </div>
              <div v-if="selectedElement.type === 8">
                <v-text-field v-model="texteditorDialog.content.value"
                              :label="$t('labels.radioValue')"
                              :hint="$t('hints.requiredField')"
                              :rules="[$store.state.inputRules.requiredField]"
                              persistent-hint
                              dense
                              filled>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="cancelNewTexteditor" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary"
                 :disabled="texteditorDialog.content.value && texteditorDialog.content.label.length < 1"
                 @click="saveNewTexteditorContent(texteditorDialog.addOrEdit)">
            {{ texteditorDialog.addOrEdit === 'ADD' ? $t('button.save') : $t('button.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SZÖVEGSZERKESZTŐ END -->
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'BasicForm',
  components: {
    draggable,
  },
  props: {
    //
  },
  mounted() {
    // console.log(this.$refs);
  },
  data() {
    return {
      form: {
        blocks: [
          {
            settings: {
              displayStyle: 1,
              title: 'Blokk címe',
              titleSize: 'h4',
              titleAlign: 'center',
              showTitle: 1,
              xsWidth: 1,
              smWidth: 1,
              mdWidth: 1,
              lgWidth: 1,
            },
            fields: [
              {
                type: 1,
                label: 'Minta',
                isRequired: 1,
                xsWidth: 1,
                smWidth: 2,
                mdWidth: 2,
                lgWidth: 2,
                value: null,
              },
              {
                type: 4,
                label: 'Dátum - Naptár',
                isRequired: 1,
                xsWidth: 1,
                smWidth: 2,
                mdWidth: 2,
                lgWidth: 2,
                value: new Date().toISOString().substr(0, 10),
              },
              {
                type: 5,
                label: 'Dátum - Irányított',
                isRequired: 1,
                xsWidth: 1,
                smWidth: 2,
                mdWidth: 2,
                lgWidth: 2,
                value: new Date().toISOString().substr(0, 10),
              },
              {
                type: 6,
                label: 'Dátum - Beírós',
                isRequired: 1,
                xsWidth: 1,
                smWidth: 2,
                mdWidth: 2,
                lgWidth: 2,
                value: new Date().toISOString().substr(0, 10),
              },
            ],
          },
          {
            settings: {
              displayStyle: 2,
              title: 'Blokk címe',
              titleSize: 'h4',
              titleAlign: 'center',
              showTitle: 2,
              xsWidth: 1,
              smWidth: 1,
              mdWidth: 1,
              lgWidth: 1,
            },
            fields: [
              {
                type: 7,
                label: 'Válasszon a lehetőségek közül',
                children: [
                  {
                    label: '<p>Az <a href="https://www.google.com" target="_blank">Általános Szerződési Feltételeket</a> elolvastam.</p>',
                    value: null,
                    isRequired: 2,
                  },
                  {
                    label: '<p>A kedvenc keresőm a <a href="https://www.google.com" target="_blank">Google</a>.</p>',
                    value: null,
                    isRequired: 1,
                  },
                ],
                showLabel: 1,
                direction: 1,
                xsWidth: 1,
                smWidth: 1,
                mdWidth: 1,
                lgWidth: 1,
                value: null,
              },
              {
                type: 8,
                label: 'Válasszon a lehetőségek közül',
                children: [
                  {
                    label: '<p>Az <a href="https://www.google.com" target="_blank">Általános Szerződési Feltételeket</a> elolvastam.</p>',
                    value: 'ASZF',
                    isRequired: null,
                  },
                  {
                    label: '<p>A kedvenc keresőm a <a href="https://www.google.com" target="_blank">Google</a>.</p>',
                    value: 'Google',
                    isRequired: null,
                  },
                ],
                showLabel: 1,
                isRequired: 2,
                direction: 1,
                xsWidth: 1,
                smWidth: 1,
                mdWidth: 1,
                lgWidth: 1,
                value: null,
              },
            ],
          },
        ],
        settings: {
          showTitle: 1,
          title: 'Kapcsolati űrlap',
          titleSize: 'h2',
          titleAlign: 'center',
          submit: {
            text: 'Küldés',
            align: 'end',
            display: 1,
            isBlock: 1,
            rounded: 1,
            size: 1,
          },
        },
      },
      selectedElement: {
        type: null,
        label: null,
        children: [],
        direction: null,
        showLabel: 1,
        isRequired: null,
        xsWidth: null,
        smWidth: null,
        mdWidth: null,
        lgWidth: null,
        value: null,
      },
      selectedBlock: {
        settings: {
          displayStyle: null,
          title: null,
          titleSize: null,
          titleAlign: null,
          showTitle: null,
          xsWidth: null,
          smWidth: null,
          mdWidth: null,
          lgWidth: null,
        },
        fields: [],
      },
      availableElements: [
        {
          id: 1,
          text: 'Egyszerű szöveg',
        },
        {
          id: 2,
          text: 'Választó lista',
        },
        {
          id: 3,
          text: 'Szöveges terület',
        },
        {
          id: 4,
          text: 'Dátum választó - Naptár',
        },
        {
          id: 5,
          text: 'Dátum választó - Irányított',
        },
        {
          id: 6,
          text: 'Dátum',
        },
        {
          id: 7,
          text: 'Jelölőnégyzet',
        },
        {
          id: 8,
          text: 'Válsztógomb',
        },
      ],
      selectFieldOptions: {
        isRequiredOptions: [
          {
            id: 1,
            text: 'Opcionális',
          },
          {
            id: 2,
            text: 'Kötelező',
          },
        ],
        widths: [
          {
            value: 1,
            text: '100%',
          },
          {
            value: 2,
            text: '50%',
          },
          {
            value: 3,
            text: '33%',
          },
          {
            value: 4,
            text: '25%',
          },
        ],
        titleSizes: [
          {
            value: 'h1',
            text: 'H1',
          },
          {
            value: 'h2',
            text: 'H2',
          },
          {
            value: 'h3',
            text: 'H3',
          },
          {
            value: 'h4',
            text: 'H4',
          },
          {
            value: 'h5',
            text: 'H5',
          },
          {
            value: 'h6',
            text: 'H6',
          },
        ],
        titleAlign: [
          {
            value: 'start',
            text: 'Balra igazítás',
          },
          {
            value: 'center',
            text: 'Középre igazítás',
          },
          {
            value: 'end',
            text: 'Jobbra igazítás',
          },
        ],
        yesOrNo: [
          {
            value: 1,
            text: 'Igen',
          },
          {
            value: 2,
            text: 'Nem',
          },
        ],
        formBackground: [
          {
            value: 1,
            text: 'Megjelenés kártyán',
          },
          {
            value: 2,
            text: 'Egyszerű',
          },
        ],
        submitDisplay: [
          {
            value: 1,
            text: 'Normál',
          },
          {
            value: 2,
            text: 'Körvonalazott',
          },
          {
            value: 3,
            text: 'Szöveg',
          },
        ],
        submitIsBlock: [
          {
            value: 1,
            text: 'Automatikus',
          },
          {
            value: 2,
            text: 'Blokk - 100%',
          },
        ],
        submitRounded: [
          {
            value: 1,
            text: 'Normál',
          },
          {
            value: 2,
            text: 'Lekerekített',
          },
        ],
        submitSize: [
          {
            value: 1,
            text: 'Normál',
          },
          {
            value: 2,
            text: 'Kicsi',
          },
          {
            value: 3,
            text: 'Extra kicsi',
          },
          {
            value: 4,
            text: 'Nagy',
          },
          {
            value: 5,
            text: 'Extra nagy',
          },
        ],
      },
      formSettings: false,
      newFieldForm: false,
      newBlockForm: false,
      newFieldDialog: {
        isVisible: false,
        action: 'ADD',
        bindex: null,
        findex: null,
      },
      newBlockDialog: {
        isVisible: false,
        action: 'ADD',
        bindex: null,
      },
      previewDialog: false,
      datePickerDialog: {
        isVisible: false,
        pickerType: 1,
        findex: null,
        bindex: null,
      },
      texteditorDialog: {
        isVisible: false,
        addOrEdit: 'ADD',
        findex: null,
        content: {
          label: '<p>Írjunk ide valamit...</p>',
          value: null,
          isRequired: null,
        },
      },
      dateTextMask: '####-##-##',
      textEditorContent: '<p>Írjunk ide valamit...</p>',
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  methods: {
    // INPUTOK KEZELÉSE
    addFieldToBlock() {
      if (this.selectedElement.type === 7) {
        // this.selectedElement.label = JSON.parse(JSON.stringify(this.textEditorContent));
        this.textEditorContent = '';
        // this.textEditor.setContent('');
      }
      this.form.blocks[this.newFieldDialog.bindex].fields.push(JSON.parse(JSON.stringify(this.selectedElement)));
      this.closeFieldDialog();
    },
    openFieldDialog(bindex) {
      this.newFieldDialog.isVisible = true;
      this.newFieldDialog.bindex = bindex;
    },
    editFieldInDialog(item, bindex, findex) {
      this.selectedElement = JSON.parse(JSON.stringify(item));
      this.newFieldDialog.findex = findex;
      this.newFieldDialog.action = 'EDIT';
      if (this.selectedElement.type === 7) {
        this.textEditorContent = JSON.parse(JSON.stringify(this.selectedElement.label));
        // this.textEditor.setContent(this.selectedElement.label);
      }
      this.openFieldDialog(bindex);
    },
    updateField() {
      if (this.selectedElement.type === 7) {
        // this.selectedElement.label = JSON.parse(JSON.stringify(this.textEditorContent));
        this.textEditorContent = '';
        // this.textEditor.setContent('');
      }
      this.form.blocks[this.newFieldDialog.bindex].fields[this.newFieldDialog.findex] = JSON.parse(JSON.stringify(this.selectedElement));
      this.closeFieldDialog();
    },
    closeFieldDialog() {
      this.newFieldDialog.isVisible = false;
      this.newFieldDialog.bindex = null;
      this.newFieldDialog.findex = null;
      this.newFieldDialog.action = 'ADD';
      this.$refs.newFieldForm.reset();
      this.selectedElement.label = undefined;
      this.selectedElement.children = [];
      this.selectedElement.direction = null;
    },
    deleteField(bindex, findex) {
      this.form.blocks[bindex].fields.splice(findex, 1);
    },
    // BLOKKOK KEZELÉSE
    addBlockToForm() {
      this.form.blocks.push(JSON.parse(JSON.stringify(this.selectedBlock)));
      this.closeBlockDialog();
    },
    openBlockDialog() {
      this.newBlockDialog.isVisible = true;
    },
    editBlockInDialog(settings, bindex) {
      this.selectedBlock.settings = JSON.parse(JSON.stringify(settings));
      this.newBlockDialog.bindex = bindex;
      this.newBlockDialog.action = 'EDIT';
      this.openBlockDialog();
    },
    updateBlock() {
      this.form.blocks[this.newBlockDialog.bindex].settings = JSON.parse(JSON.stringify(this.selectedBlock.settings));
      this.closeBlockDialog();
    },
    closeBlockDialog() {
      this.newBlockDialog.isVisible = false;
      this.newBlockDialog.bindex = null;
      this.newBlockDialog.action = 'ADD';
      this.$refs.newBlockForm.reset();
    },
    deleteBlock(bindex) {
      this.form.blocks.splice(bindex, 1);
    },
    // ŰRLAP KEZELÉSE
    openFormPreview() {
      this.previewDialog = true;
    },
    closeFormPreview() {
      this.previewDialog = false;
    },
    saveForm() {
      // console.log('saveForm');
    },
    // DATEPICKER KEZELÉS
    openDatePicker(item, bindex, findex, pickerType) {
      this.selectedElement = JSON.parse(JSON.stringify(item));
      this.datePickerDialog.bindex = bindex;
      this.datePickerDialog.findex = findex;
      this.datePickerDialog.pickerType = pickerType;
      if (pickerType === 2) {
        setTimeout(() => (this.$refs.datePicker.activePicker = 'YEAR'));
      } else {
        setTimeout(() => (this.$refs.datePicker.activePicker = 'DATE'));
      }
      this.datePickerDialog.isVisible = true;
    },
    saveDateFromDatePicker() {
      this.form.blocks[this.datePickerDialog.bindex].fields[this.datePickerDialog.findex] = JSON.parse(JSON.stringify(this.selectedElement));
      this.closeDatePicker();
    },
    closeDatePicker() {
      this.datePickerDialog.bindex = null;
      this.datePickerDialog.findex = null;
      this.datePickerDialog.pickerType = 1;
      this.datePickerDialog.isVisible = false;
    },
    // SZÖVEGSZERKESZTŐ LINK KEZELÉS
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url, target: '_blank' });
      this.hideLinkMenu();
    },
    // SZÖVEGSZERKESZTŐ DIALOG KEZELÉSE
    openTexteditorDialog() {
      this.texteditorDialog.isVisible = true;
      this.textEditorContent = this.texteditorDialog.content.label;
      // this.textEditor.setContent(this.textEditorContent);
    },
    editTexteditorContent(radio, findex) {
      this.texteditorDialog.findex = findex;
      this.texteditorDialog.content = JSON.parse(JSON.stringify(radio));
      this.texteditorDialog.addOrEdit = 'EDIT';
      this.textEditorContent = radio.label;
      // this.textEditor.setContent(this.textEditorContent);
      this.texteditorDialog.isVisible = true;
    },
    saveNewTexteditorContent(type) {
      if (type === 'ADD') {
        this.selectedElement.children.push(JSON.parse(JSON.stringify(this.texteditorDialog.content)));
      } else {
        this.selectedElement.children[this.texteditorDialog.findex] = JSON.parse(JSON.stringify(this.texteditorDialog.content));
      }
      this.textEditorContent = '<p>Írjunk ide valamit...</p>';
      // this.textEditor.setContent(this.textEditorContent);
      this.texteditorDialog.isVisible = false;
      this.setTexteditorDialogToDefault();
    },
    cancelNewTexteditor() {
      this.texteditorDialog.isVisible = false;
      this.setTexteditorDialogToDefault();
    },
    setTexteditorDialogToDefault() {
      const texteditorDialogDefault = {
        isVisible: false,
        addOrEdit: 'ADD',
        findex: null,
        content: {
          label: '<p>Írjunk ide valamit...</p>',
          value: null,
        },
      };
      this.texteditorDialog = JSON.parse(JSON.stringify(texteditorDialogDefault));
      /* this.texteditorDialog.addOrEdit = 'ADD';
      this.texteditorDialog.content = {
        label: '<p>Írjunk ide valamit...</p>',
        value: null,
      }; */
    },
    deleteRadioOrChb(index) {
      this.selectedElement.children.splice(index, 1);
    },
  },
  computed: {
    newFieldDialogActionButtonIsDisabled() {
      if (this.selectedElement.type === 7 || this.selectedElement.type === 8) {
        return !(this.newFieldForm && this.selectedElement.children.length > 0);
      }
      return !this.newFieldForm;
    },
  },
};
</script>

<style scoped>

</style>
