<template>
  <v-container fluid>
    <template v-if="form === 1">
      <basic-form></basic-form>
    </template>
  </v-container>
</template>

<script>
import BasicForm from '@/components/formTemplates/BasicForm.vue';

export default {
  name: 'FormBuilderEditor',
  components: {
    BasicForm,
  },
  props: {
    //
  },
  async beforeMount() {
    this.form = parseInt(this.$route.params.formId, 10);
  },
  data() {
    return {
      form: null,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
